<template>
  <v-layout max-width class="flex-column white" style="min-height:100%;">
    <!-- <div class="flex-y-center flex-justify-between px-2" style="border-bottom:1px solid #e8e8e8 ">
            <v-tabs v-model="tab">
                <v-tab :href="`#1`">前台</v-tab>
                <v-tab :href="`#2`">后台</v-tab>
            </v-tabs>
        </div> -->
    <div class=" flex-1 px-12 mt-12 pt-12" style="">
      <!-- <v-row no-gutters class="flex-y-center mb-8">
                <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
                    登录页logo：
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <input class=" default-input" style="width:200px;" v-model="data.old"/>
                </v-col>
            </v-row>
            <v-row no-gutters class="flex-y-center mb-8">
                <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
                    首页logo：
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <input class=" default-input" style="width:200px;" v-model="data.old"/>
                </v-col>
            </v-row>
            <v-row no-gutters class="flex-y-center mb-8">
                <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
                    背景图logo：
                </v-col>
                <v-col cols="12" sm="6" md="6">
                    <input class=" default-input" style="width:200px;" v-model="data.old"/>
                </v-col>
            </v-row> -->
      <!-- <v-row no-gutters class="flex-y-center mb-8">
                <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
                    系统名称：
                </v-col>
                <v-col cols="12" sm="6" md="6" class=" flex-y-center">
                    <input class=" default-input mr-3" style="width:200px;" v-model="data.old"/>
                    <v-menu
                        :close-on-content-click="false"
                        bottom
                        max-height="300"
                        offset-overflow
                        transition="slide-y-transition"
                        offset-y
                        >
                        <template v-slot:activator="{ on }">
                            <div class="default-input mr-3" style="width:30px !important;" color="primary" v-on="on">
                                <input placeholder="试验类型" class=" max-width" type="text" v-model="search.data.project_name" />
                            </div>
                        </template>
                        <v-list class="pa-0 radius-0" subheader>
                            <v-list-item :key="index" v-for="(item,index) in shiyan" >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-menu
                        :close-on-content-click="false"
                        bottom
                        max-height="300"
                        offset-overflow
                        transition="slide-y-transition"
                        offset-y
                        >
                        <template v-slot:activator="{ on }">
                            <div class="default-input " style="width:30px !important;" color="primary" v-on="on">
                                <input placeholder="试验类型" class=" max-width" type="text" v-model="search.data.project_name" />
                            </div>
                        </template>
                        <v-list class="pa-0 radius-0" subheader>
                            <v-list-item :key="index" v-for="(item,index) in shiyan" >
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row> -->
      <v-row v-for="(item, index) in commonInfo" :key="index" no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="4" md="4" class=" flex-justify-end">
          {{ item.desc }}：
        </v-col>
        <v-col cols="12" sm="6" md="6" class=" flex-y-center">
          <form-item
            v-model="data[item.key]"
            :placeholder="item.desc"
            type="text"
            allow-close
            @input="$forceUpdate()"
          />
        </v-col>
      </v-row>
      <v-row v-if="commonInfo.length" no-gutters class="flex-y-center mb-8">
        <v-col cols="12" sm="4" md="4" class=" flex-justify-end" />
        <v-col cols="12" sm="6" md="6">
          <v-btn color="primary" depressed class="mt-4" height="32" width="100" @click="submit">保存</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      tab: '1',
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      data: {},
      commonInfo: [],
      shiyan: []
    }
  },
  mounted() {
    this.settingGetCommonInfo()
  },
  methods: {
    // 获取信息
    settingGetCommonInfo() {
      this.$http.get(this.$api.settingGetCommonInfo, { data: '' }).then(res => {
        this.commonInfo = res.result || []
        this.commonInfo.forEach(item => {
          this.data[item.key] = item.value
        })
      })
    },
    // 保存
    submit() {
      this.$http.post(this.$api.settingSaveCommonInfo, { data: this.data }).then(res => {
        this.$message.success(res.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:130px;
    color: #777;
}
</style>
